// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonToggleVideoIframeVisibility_button__PQvIP {
  position: absolute;
  left: calc(-2.75rem * var(--mantine-scale));
  top: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  z-index: 5;
  transition: 0.2s;
}
`, "",{"version":3,"sources":["webpack://./src/components/ButtonToggleVideoIframeVisibility.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,2CAAgB;EAChB,MAAM;EACN,0BAA0B;EAC1B,6BAA6B;EAC7B,UAAU;EACV,gBAAgB;AAClB","sourcesContent":[".button {\n  position: absolute;\n  left: rem(-44px);\n  top: 0;\n  border-top-right-radius: 0;\n  border-bottom-right-radius: 0;\n  z-index: 5;\n  transition: 0.2s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `ButtonToggleVideoIframeVisibility_button__PQvIP`
};
export default ___CSS_LOADER_EXPORT___;
