// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MobilePlayer_container__trYGI {
  position: absolute;
  bottom: calc(3.125rem * var(--mantine-scale));
  padding-bottom: calc(0.625rem * var(--mantine-scale));
  left: 0;
  right: 0;
  z-index: 2;
}

.MobilePlayer_content__P7k\\+c {
  padding: var(--mantine-spacing-xs);
  padding-right: var(--mantine-spacing-md);
  padding-left: var(--mantine-spacing-md);
  gap: var(--mantine-spacing-xs);
  text-align: center;
  justify-content: space-between;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/MobilePlayer.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,6CAAiB;EACjB,qDAAyB;EACzB,OAAO;EACP,QAAQ;EACR,UAAU;AACZ;;AAEA;EACE,kCAAkC;EAClC,wCAAwC;EACxC,uCAAuC;EACvC,8BAA8B;EAC9B,kBAAkB;EAClB,8BAA8B;EAC9B,mBAAmB;AACrB","sourcesContent":[".container {\n  position: absolute;\n  bottom: rem(50px);\n  padding-bottom: rem(10px);\n  left: 0;\n  right: 0;\n  z-index: 2;\n}\n\n.content {\n  padding: var(--mantine-spacing-xs);\n  padding-right: var(--mantine-spacing-md);\n  padding-left: var(--mantine-spacing-md);\n  gap: var(--mantine-spacing-xs);\n  text-align: center;\n  justify-content: space-between;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MobilePlayer_container__trYGI`,
	"content": `MobilePlayer_content__P7k+c`
};
export default ___CSS_LOADER_EXPORT___;
