// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalSyncData_column__RxM0F {
  max-width: calc(2.875rem * var(--mantine-scale));
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/ModalSyncData.module.css"],"names":[],"mappings":"AAAA;EACE,gDAAoB;EACpB,kBAAkB;AACpB","sourcesContent":[".column {\n  max-width: rem(46px);\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"column": `ModalSyncData_column__RxM0F`
};
export default ___CSS_LOADER_EXPORT___;
