// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Navigation_navbar__jFJDL {
  position: relative;
  background: var(--mantine-color-gray-0);
}
  [data-mantine-color-scheme='dark'] .Navigation_navbar__jFJDL {
  background: var(--mantine-color-dark-6);
}
  .Navigation_navbar__jFJDL {
  padding: var(--mantine-spacing-md);
  z-index: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/Navigation.module.css","<no source>"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,uCAGC;AAGH;ECRA;EDEE;CCFF;EAAA;EDME,kCAAkC;EAClC;CCPF","sourcesContent":[".navbar {\n  position: relative;\n  background: light-dark(\n    var(--mantine-color-gray-0),\n    var(--mantine-color-dark-6)\n  );\n  padding: var(--mantine-spacing-md);\n  z-index: 1;\n}\n",null],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbar": `Navigation_navbar__jFJDL`
};
export default ___CSS_LOADER_EXPORT___;
