// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TransferList_container__JyvW6 {
  gap: var(--mantine-spacing-md);
}

  @media (max-width: 47.1875em) {.TransferList_container__JyvW6 {
    flex-direction: column
}
  }

.TransferList_controls__vWUIs {

  /* > div {
    width: 100%;
  } */
}

[data-type="backward"] .TransferList_controls__vWUIs {
    flex-direction: row-reverse;
  }

.TransferList_renderList__ThLTA {
  flex: 1;
}

.TransferList_list__Bmt2w {
  background-color: var(--mantine-color-body);
  padding: var(--mantine-spacing-xs) calc(0.375rem * var(--mantine-scale));
  border: calc(0.0625rem * var(--mantine-scale)) solid
    var(--mantine-color-gray-4);
}

[data-mantine-color-scheme='dark'] .TransferList_list__Bmt2w {
  border: calc(0.0625rem * var(--mantine-scale)) solid
    var(--mantine-color-dark-4);
}

.TransferList_list__Bmt2w {
  border-top: 0;
  border-bottom-left-radius: var(--mantine-radius-md);
  border-bottom-right-radius: var(--mantine-radius-md);
  min-height: calc(14rem * var(--mantine-scale));
}

.TransferList_input__tyLzP {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

[data-type="backward"] .TransferList_input__tyLzP {
    border-left: 0;
    border-top-left-radius: 0;
  }

[data-type="forward"] .TransferList_input__tyLzP {
    border-right: 0;
    border-top-right-radius: 0;
  }

[data-type="backward"] .TransferList_control__8-Pkp {
    border-top-left-radius: var(--mantine-radius-sm);
  }

[data-type="forward"] .TransferList_control__8-Pkp {
    border-top-right-radius: var(--mantine-radius-sm);
  }

.TransferList_icon__DPTvm {
  width: calc(1.125rem * var(--mantine-scale));
  height: calc(1.125rem * var(--mantine-scale));
}

[data-type="backward"] .TransferList_icon__DPTvm {
    transform: rotate(180deg);
  }
`, "",{"version":3,"sources":["webpack://./src/components/TransferList.module.css","<no source>"],"names":[],"mappings":"AAAA;EACE,8BAA8B;AAKhC;;EAHE,+BAHF;IAII;AAEJ;EADE;;AAGF;;EAKE;;KAEG;AACL;;AAPE;IACE,2BAA2B;EAC7B;;AAOF;EACE,OAAO;AACT;;AAEA;EACE,2CAA2C;EAC3C,wEAA2C;EAC3C;+BACsE;AAKxE;;AC/BA;EDyBE;;CCzBF;;AAAA;ED2BE,aAAa;EACb,mDAAmD;EACnD,oDAAoD;EACpD;CC9BF;;ADiCA;EACE,4BAA4B;EAC5B,6BAA6B;AAW/B;;AATE;IACE,cAAc;IACd,yBAAyB;EAC3B;;AAEA;IACE,eAAe;IACf,0BAA0B;EAC5B;;AAIA;IACE,gDAAgD;EAClD;;AAEA;IACE,iDAAiD;EACnD;;AAGF;EACE,4CAAgB;EAChB,6CAAiB;AAKnB;;AAHE;IACE,yBAAyB;EAC3B","sourcesContent":[".container {\n  gap: var(--mantine-spacing-md);\n\n  @media (max-width: em(755px)) {\n    flex-direction: column;\n  }\n}\n\n.controls {\n  [data-type=\"backward\"] & {\n    flex-direction: row-reverse;\n  }\n\n  /* > div {\n    width: 100%;\n  } */\n}\n\n.renderList {\n  flex: 1;\n}\n\n.list {\n  background-color: var(--mantine-color-body);\n  padding: var(--mantine-spacing-xs) rem(6px);\n  border: rem(1px) solid\n    light-dark(var(--mantine-color-gray-4), var(--mantine-color-dark-4));\n  border-top: 0;\n  border-bottom-left-radius: var(--mantine-radius-md);\n  border-bottom-right-radius: var(--mantine-radius-md);\n  min-height: rem(224px);\n}\n\n.input {\n  border-bottom-left-radius: 0;\n  border-bottom-right-radius: 0;\n\n  [data-type=\"backward\"] & {\n    border-left: 0;\n    border-top-left-radius: 0;\n  }\n\n  [data-type=\"forward\"] & {\n    border-right: 0;\n    border-top-right-radius: 0;\n  }\n}\n\n.control {\n  [data-type=\"backward\"] & {\n    border-top-left-radius: var(--mantine-radius-sm);\n  }\n\n  [data-type=\"forward\"] & {\n    border-top-right-radius: var(--mantine-radius-sm);\n  }\n}\n\n.icon {\n  width: rem(18px);\n  height: rem(18px);\n\n  [data-type=\"backward\"] & {\n    transform: rotate(180deg);\n  }\n}\n",null],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TransferList_container__JyvW6`,
	"controls": `TransferList_controls__vWUIs`,
	"renderList": `TransferList_renderList__ThLTA`,
	"list": `TransferList_list__Bmt2w`,
	"input": `TransferList_input__tyLzP`,
	"control": `TransferList_control__8-Pkp`,
	"icon": `TransferList_icon__DPTvm`
};
export default ___CSS_LOADER_EXPORT___;
