// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  overflow: hidden;
}

html {
  height: -webkit-fill-available;
}

.App {
  height: 100vh;
  flex-direction: column;
}

.App.iOS {
  height: -webkit-fill-available;
}

/* This "static" media will be updated after upgrade Mantine version to 7 with CSS module */
@media screen and (min-width: 648px) {
  .App {
    flex-direction: row;
  }
}

.App-Content {
  overflow-y: auto;
  flex: 1;
  max-height: 100vh;
}
`, "",{"version":3,"sources":["webpack://./src/components/App.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kCAAkC;EAClC,gBAAgB;AAClB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,8BAA8B;AAChC;;AAEA,2FAA2F;AAC3F;EACE;IACE,mBAAmB;EACrB;AACF;;AAEA;EACE,gBAAgB;EAChB,OAAO;EACP,iBAAiB;AACnB","sourcesContent":["body {\n  min-height: 100vh;\n  min-height: -webkit-fill-available;\n  overflow: hidden;\n}\n\nhtml {\n  height: -webkit-fill-available;\n}\n\n.App {\n  height: 100vh;\n  flex-direction: column;\n}\n\n.App.iOS {\n  height: -webkit-fill-available;\n}\n\n/* This \"static\" media will be updated after upgrade Mantine version to 7 with CSS module */\n@media screen and (min-width: 648px) {\n  .App {\n    flex-direction: row;\n  }\n}\n\n.App-Content {\n  overflow-y: auto;\n  flex: 1;\n  max-height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
