// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DrawerPlayer_card__76W1o {
  position: sticky;
  top: 0;
  height: 100vh;
  transition: 0.2s;
}

.DrawerPlayer_scrollArea__Hk9Os {
  height: calc(100vh - calc(31.25rem * var(--mantine-scale)));
}

.DrawerPlayer_thumbnail__5eMSM {
  max-width: 100%;
  border-radius: var(--mantine-radius-md);
  margin-bottom: var(--mantine-spacing-xl);
}

@media (min-width: 50em) {

.DrawerPlayer_thumbnail__5eMSM {
    max-width: calc(20rem * var(--mantine-scale))
}
  }

.DrawerPlayer_progressContainer__jjnsS {
  width: 100%;
}

@media (min-width: 50em) {

.DrawerPlayer_progressContainer__jjnsS {
    padding-left: var(--mantine-spacing-xl);
    padding-right: var(--mantine-spacing-xl)
}
  }
`, "",{"version":3,"sources":["webpack://./src/components/DrawerPlayer.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,MAAM;EACN,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,2DAAgC;AAClC;;AAEA;EACE,eAAe;EACf,uCAAuC;EACvC,wCAAwC;AAK1C;;AAHE;;AALF;IAMI;AAEJ;EADE;;AAGF;EACE,WAAW;AAMb;;AAJE;;AAHF;IAII,uCAAuC;IACvC;AAEJ;EADE","sourcesContent":[".card {\n  position: sticky;\n  top: 0;\n  height: 100vh;\n  transition: 0.2s;\n}\n\n.scrollArea {\n  height: calc(100vh - rem(500px));\n}\n\n.thumbnail {\n  max-width: 100%;\n  border-radius: var(--mantine-radius-md);\n  margin-bottom: var(--mantine-spacing-xl);\n\n  @media (min-width: $mantine-breakpoint-sm) {\n    max-width: rem(320px);\n  }\n}\n\n.progressContainer {\n  width: 100%;\n\n  @media (min-width: $mantine-breakpoint-sm) {\n    padding-left: var(--mantine-spacing-xl);\n    padding-right: var(--mantine-spacing-xl);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `DrawerPlayer_card__76W1o`,
	"scrollArea": `DrawerPlayer_scrollArea__Hk9Os`,
	"thumbnail": `DrawerPlayer_thumbnail__5eMSM`,
	"progressContainer": `DrawerPlayer_progressContainer__jjnsS`
};
export default ___CSS_LOADER_EXPORT___;
