// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CardList_flexGrid__8V1GY {
  display: flex;
  flex-direction: row;
  gap: var(--mantine-spacing-lg);
  overflow: auto;
}

.CardList_flexColumn__-\\+Y56 {
  flex: 0 0 calc(17.3125rem * var(--mantine-scale));
}
`, "",{"version":3,"sources":["webpack://./src/components/CardList.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,cAAc;AAChB;;AAEA;EACE,iDAAoB;AACtB","sourcesContent":[".flexGrid {\n  display: flex;\n  flex-direction: row;\n  gap: var(--mantine-spacing-lg);\n  overflow: auto;\n}\n\n.flexColumn {\n  flex: 0 0 rem(277px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flexGrid": `CardList_flexGrid__8V1GY`,
	"flexColumn": `CardList_flexColumn__-+Y56`
};
export default ___CSS_LOADER_EXPORT___;
