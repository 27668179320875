// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VerticalSlider_container__iUH9k {
  position: relative;
  width: calc(0.25rem * var(--mantine-scale));
  height: calc(7.5rem * var(--mantine-scale));
  border-radius: var(--mantine-radius-md);
  cursor: pointer;
}

  [data-mantine-color-scheme="light"] .VerticalSlider_container__iUH9k {
    background: rgba(0, 0, 0, 0.1);
  }

  [data-mantine-color-scheme="dark"] .VerticalSlider_container__iUH9k {
    background: rgba(255, 255, 255, 0.1);
  }

.VerticalSlider_filledBar__LGU9h {
  position: absolute;
  bottom: 0;
  width: calc(0.25rem * var(--mantine-scale));
  border-radius: var(--mantine-radius-md);
}

[data-mantine-color-scheme="light"] .VerticalSlider_filledBar__LGU9h {
    background: var(--mantine-color-blue-6);
  }

[data-mantine-color-scheme="dark"] .VerticalSlider_filledBar__LGU9h {
    background: white;
  }
`, "",{"version":3,"sources":["webpack://./src/components/VerticalSlider.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,2CAAe;EACf,2CAAkB;EAClB,uCAAuC;EACvC,eAAe;AAQjB;;EANE;IACE,8BAA8B;EAChC;;EACA;IACE,oCAAoC;EACtC;;AAGF;EACE,kBAAkB;EAClB,SAAS;EACT,2CAAe;EACf,uCAAuC;AAQzC;;AANE;IACE,uCAAuC;EACzC;;AACA;IACE,iBAAiB;EACnB","sourcesContent":[".container {\n  position: relative;\n  width: rem(4px);\n  height: rem(120px);\n  border-radius: var(--mantine-radius-md);\n  cursor: pointer;\n\n  [data-mantine-color-scheme=\"light\"] & {\n    background: rgba(0, 0, 0, 0.1);\n  }\n  [data-mantine-color-scheme=\"dark\"] & {\n    background: rgba(255, 255, 255, 0.1);\n  }\n}\n\n.filledBar {\n  position: absolute;\n  bottom: 0;\n  width: rem(4px);\n  border-radius: var(--mantine-radius-md);\n\n  [data-mantine-color-scheme=\"light\"] & {\n    background: var(--mantine-color-blue-6);\n  }\n  [data-mantine-color-scheme=\"dark\"] & {\n    background: white;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `VerticalSlider_container__iUH9k`,
	"filledBar": `VerticalSlider_filledBar__LGU9h`
};
export default ___CSS_LOADER_EXPORT___;
