// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Main_main__Uxwo5 {
  flex: 1;
  padding: calc(5rem * var(--mantine-scale)) calc(1.25rem * var(--mantine-scale));
}

  @media (max-width: 30em) {.Main_main__Uxwo5 {
    min-height: calc(100vh - calc(8rem * var(--mantine-scale)))
}
  }

  @media (min-width: 62em) {.Main_main__Uxwo5 {
    padding: calc(1rem * var(--mantine-scale)) calc(1.75rem * var(--mantine-scale)) calc(1.5rem * var(--mantine-scale))
}
  }
`, "",{"version":3,"sources":["webpack://./src/components/Main.module.css"],"names":[],"mappings":"AAAA;EACE,OAAO;EACP,+EAA4B;AAS9B;;EAPE,0BAJF;IAKI;AAMJ;EALE;;EAEA,0BARF;IASI;AAEJ;EADE","sourcesContent":[".main {\n  flex: 1;\n  padding: rem(80px) rem(20px);\n\n  @media (max-width: $mantine-breakpoint-xs) {\n    min-height: calc(100vh - rem(128px));\n  }\n\n  @media (min-width: $mantine-breakpoint-md) {\n    padding: rem(16px) rem(28px) rem(24px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `Main_main__Uxwo5`
};
export default ___CSS_LOADER_EXPORT___;
