// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CardImage_imageContainer__QdFr9 {
  overflow: hidden;
  padding: var(--mantine-spacing-sm);
  position: relative;
  min-height: calc(9.5rem * var(--mantine-scale));
  border-radius: var(--mantine-spacing-sm);
}

.CardImage_image__wK\\+JP {
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 110%;
  border-radius: var(--mantine-radius-sm);
  transform: translate3d(-50%, -50%, 0);
}
`, "",{"version":3,"sources":["webpack://./src/components/CardImage.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kCAAkC;EAClC,kBAAkB;EAClB,+CAAsB;EACtB,wCAAwC;AAC1C;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gBAAgB;EAChB,uCAAuC;EACvC,qCAAqC;AACvC","sourcesContent":[".imageContainer {\n  overflow: hidden;\n  padding: var(--mantine-spacing-sm);\n  position: relative;\n  min-height: rem(152px);\n  border-radius: var(--mantine-spacing-sm);\n}\n\n.image {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  max-height: 110%;\n  border-radius: var(--mantine-radius-sm);\n  transform: translate3d(-50%, -50%, 0);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageContainer": `CardImage_imageContainer__QdFr9`,
	"image": `CardImage_image__wK+JP`
};
export default ___CSS_LOADER_EXPORT___;
