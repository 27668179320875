// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchBar_container__F0JM1 {
  width: 100%;
}

  @media (min-width: 50em) {.SearchBar_container__F0JM1 {
    max-width: calc(28.75rem * var(--mantine-scale))
}
  }

  @media (min-width: 1900px) {.SearchBar_container__F0JM1 {
    max-width: calc(33.75rem * var(--mantine-scale))
}
  }

.SearchBar_form__ngDTv {
  flex: 1;
}

.SearchBar_form__ngDTv input {
    /* iOS Safari zoom fix : https://defensivecss.dev/tip/input-zoom-safari */
    font-size: 16px;
  }

.SearchBar_kbd__NcD6u {
  height: calc(1.375rem * var(--mantine-scale));
  line-height: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/SearchBar.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AASb;;EAPE,0BAHF;IAII;AAMJ;EALE;;EAEA,4BAPF;IAQI;AAEJ;EADE;;AAGF;EACE,OAAO;AAMT;;AAJE;IACE,yEAAyE;IACzE,eAAe;EACjB;;AAGF;EACE,6CAAiB;EACjB,iBAAiB;AACnB","sourcesContent":[".container {\n  width: 100%;\n\n  @media (min-width: $mantine-breakpoint-sm) {\n    max-width: rem(460px);\n  }\n\n  @media (min-width: 1900px) {\n    max-width: rem(540px);\n  }\n}\n\n.form {\n  flex: 1;\n\n  input {\n    /* iOS Safari zoom fix : https://defensivecss.dev/tip/input-zoom-safari */\n    font-size: 16px;\n  }\n}\n\n.kbd {\n  height: rem(22px);\n  line-height: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SearchBar_container__F0JM1`,
	"form": `SearchBar_form__ngDTv`,
	"kbd": `SearchBar_kbd__NcD6u`
};
export default ___CSS_LOADER_EXPORT___;
