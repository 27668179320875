// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_container__zmwVK {
  display: flex;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  backdrop-filter: blur(8px);
  z-index: 5;
  padding: calc(1rem * var(--mantine-scale));
}
`, "",{"version":3,"sources":["webpack://./src/components/Header.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,gBAAgB;EAChB,MAAM;EACN,0BAA0B;EAC1B,UAAU;EACV,0CAAkB;AACpB","sourcesContent":[".container {\n  display: flex;\n  justify-content: flex-end;\n  position: sticky;\n  top: 0;\n  backdrop-filter: blur(8px);\n  z-index: 5;\n  padding: rem(16px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Header_container__zmwVK`
};
export default ___CSS_LOADER_EXPORT___;
