// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MobileNavigation_container__lBdKE {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(231, 245, 255, 0.8);
}
  [data-mantine-color-scheme='dark'] .MobileNavigation_container__lBdKE {
  background: rgba(25, 113, 194, 0.2);
}
  .MobileNavigation_container__lBdKE {
  backdrop-filter: blur(8px);
  z-index: 4;
  border-top-left-radius: var(--mantine-radius-md);
  border-top-right-radius: var(--mantine-radius-md);
}
`, "",{"version":3,"sources":["webpack://./src/containers/MobileNavigation.module.css","<no source>"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,oCAAyE;AAK3E;ECTA;EDIE;CCJF;EAAA;EDKE,0BAA0B;EAC1B,UAAU;EACV,gDAAgD;EAChD;CCRF","sourcesContent":[".container {\n  position: absolute;\n  bottom: 0;\n  width: 100%;\n  background: light-dark(rgba(231, 245, 255, 0.8), rgba(25, 113, 194, 0.2));\n  backdrop-filter: blur(8px);\n  z-index: 4;\n  border-top-left-radius: var(--mantine-radius-md);\n  border-top-right-radius: var(--mantine-radius-md);\n}\n",null],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MobileNavigation_container__lBdKE`
};
export default ___CSS_LOADER_EXPORT___;
