// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavbarLink_link__BrtPL {
  width: calc(2.75rem * var(--mantine-scale));
  height: calc(2.75rem * var(--mantine-scale));
  border-radius: var(--mantine-radius-md);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--mantine-color-gray-7);
}
  [data-mantine-color-scheme='dark'] .NavbarLink_link__BrtPL {
  color: var(--mantine-color-dark-0);
}
  .NavbarLink_link__BrtPL {
  margin: auto;
}

  @media (min-width: 28.75em) {.NavbarLink_link__BrtPL {
    /* margin-bottom: var(--mantine-spacing-sm); */
}

    .NavbarLink_link__BrtPL:hover {
      background: var(--mantine-color-gray-0);
    }
      [data-mantine-color-scheme='dark'] .NavbarLink_link__BrtPL:hover {
      background: var(--mantine-color-dark-5);
  }
  }

  .NavbarLink_link__BrtPL[data-active="true"],
    .NavbarLink_link__BrtPL[data-active="true"]:hover {
      background-color: var(--mantine-color-blue-light);
      color: var(--mantine-color-blue-light-color);
    }
`, "",{"version":3,"sources":["webpack://./src/components/NavbarLink.module.css","<no source>"],"names":[],"mappings":"AAAA;EACE,2CAAgB;EAChB,4CAAiB;EACjB,uCAAuC;EACvC,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kCAA2E;AAqB7E;EC5BA;EDOE;CCPF;EAAA;EDQE;CCRF;;EDUE,6BAVF;IAWI,8CAA8C;AAiBlD;;IAfI;MACE,uCAGC;IACH;MClBJ;MDcM;GCdN;EDmBE;;EAGE;;MAEE,iDAAiD;MACjD,4CAA4C;IAC9C","sourcesContent":[".link {\n  width: rem(44px);\n  height: rem(44px);\n  border-radius: var(--mantine-radius-md);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));\n  margin: auto;\n\n  @media (min-width: em(460px)) {\n    /* margin-bottom: var(--mantine-spacing-sm); */\n\n    &:hover {\n      background: light-dark(\n        var(--mantine-color-gray-0),\n        var(--mantine-color-dark-5)\n      );\n    }\n  }\n\n  &[data-active=\"true\"] {\n    &,\n    &:hover {\n      background-color: var(--mantine-color-blue-light);\n      color: var(--mantine-color-blue-light-color);\n    }\n  }\n}\n",null],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `NavbarLink_link__BrtPL`
};
export default ___CSS_LOADER_EXPORT___;
