// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SponsorBlockBar_container__usXIL {
  position: absolute;
  top: calc(0.125rem * var(--mantine-scale));
  left: 0;
  width: 100%;
  align-items: center;
  z-index: 2;
  pointer-events: none;
  border-radius: var(--mantine-radius-sm);
}

.SponsorBlockBar_segment__ZMmXs {
  height: calc(0.25rem * var(--mantine-scale));
}

[data-mantine-color-scheme="light"] .SponsorBlockBar_segment__ZMmXs {
    background: var(--mantine-color-orange-5);
  }

[data-mantine-color-scheme="dark"] .SponsorBlockBar_segment__ZMmXs {
    background: var(--mantine-color-indigo-0);
  }
`, "",{"version":3,"sources":["webpack://./src/components/SponsorBlockBar.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,0CAAa;EACb,OAAO;EACP,WAAW;EACX,mBAAmB;EACnB,UAAU;EACV,oBAAoB;EACpB,uCAAuC;AACzC;;AAEA;EACE,4CAAgB;AAQlB;;AANE;IACE,yCAAyC;EAC3C;;AACA;IACE,yCAAyC;EAC3C","sourcesContent":[".container {\n  position: absolute;\n  top: rem(2px);\n  left: 0;\n  width: 100%;\n  align-items: center;\n  z-index: 2;\n  pointer-events: none;\n  border-radius: var(--mantine-radius-sm);\n}\n\n.segment {\n  height: rem(4px);\n\n  [data-mantine-color-scheme=\"light\"] & {\n    background: var(--mantine-color-orange-5);\n  }\n  [data-mantine-color-scheme=\"dark\"] & {\n    background: var(--mantine-color-indigo-0);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SponsorBlockBar_container__usXIL`,
	"segment": `SponsorBlockBar_segment__ZMmXs`
};
export default ___CSS_LOADER_EXPORT___;
