// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PlaylistCard_card__\\+nYO6 {
  background: var(--mantine-color-gray-0);
}
  [data-mantine-color-scheme='dark'] .PlaylistCard_card__\\+nYO6 {
  background: var(--mantine-color-dark-6);
}
  .PlaylistCard_card__\\+nYO6 {
  color: var(--mantine-color-gray-7);
}
  [data-mantine-color-scheme='dark'] .PlaylistCard_card__\\+nYO6 {
  color: var(--mantine-color-dark-0);
}
  .PlaylistCard_card__\\+nYO6 {
  border-radius: var(--mantine-radius-md);
  padding: calc(1.5rem * var(--mantine-scale)) !important;
}

.PlaylistCard_video__Acu3- {
  background-color: rgba(25, 113, 194, 0.2);
  background-size: cover;
  color: rgb(165, 216, 255);
  width: calc(1.75rem * var(--mantine-scale));
  height: calc(1.75rem * var(--mantine-scale));
  border-radius: var(--mantine-radius-sm);
  margin-bottom: var(--mantine-spacing-sm);
}

.PlaylistCard_more__QO7m8 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(25, 113, 194, 0.2);
  background-size: cover;
  width: calc(1.75rem * var(--mantine-scale));
  height: calc(1.75rem * var(--mantine-scale));
  border-radius: var(--mantine-radius-sm);
  margin-bottom: var(--mantine-spacing-sm);
}

.PlaylistCard_title__qo4Jp {
  margin-bottom: var(--mantine-spacing-xs);
  margin-top: var(--mantine-spacing-md);
  cursor: pointer;
}

.PlaylistCard_titleText__eQwvb {
  font-size: calc(1.375rem * var(--mantine-scale));
}
`, "",{"version":3,"sources":["webpack://./src/components/PlaylistCard.module.css","<no source>"],"names":[],"mappings":"AAAA;EACE,uCAGC;AAIH;ECRA;EDCE;CCDF;EAAA;EDKE;CCLF;EAAA;EDKE;CCLF;EAAA;EDME,uCAAuC;EACvC;CCPF;;ADUA;EACE,yCAAyC;EACzC,sBAAsB;EACtB,yBAAyB;EACzB,2CAAgB;EAChB,4CAAiB;EACjB,uCAAuC;EACvC,wCAAwC;AAC1C;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,yCAAyC;EACzC,sBAAsB;EACtB,2CAAgB;EAChB,4CAAiB;EACjB,uCAAuC;EACvC,wCAAwC;AAC1C;;AAEA;EACE,wCAAwC;EACxC,qCAAqC;EACrC,eAAe;AACjB;;AAEA;EACE,gDAAoB;AACtB","sourcesContent":[".card {\n  background: light-dark(\n    var(--mantine-color-gray-0),\n    var(--mantine-color-dark-6)\n  );\n  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));\n  border-radius: var(--mantine-radius-md);\n  padding: rem(24px) !important;\n}\n\n.video {\n  background-color: rgba(25, 113, 194, 0.2);\n  background-size: cover;\n  color: rgb(165, 216, 255);\n  width: rem(28px);\n  height: rem(28px);\n  border-radius: var(--mantine-radius-sm);\n  margin-bottom: var(--mantine-spacing-sm);\n}\n\n.more {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: rgba(25, 113, 194, 0.2);\n  background-size: cover;\n  width: rem(28px);\n  height: rem(28px);\n  border-radius: var(--mantine-radius-sm);\n  margin-bottom: var(--mantine-spacing-sm);\n}\n\n.title {\n  margin-bottom: var(--mantine-spacing-xs);\n  margin-top: var(--mantine-spacing-md);\n  cursor: pointer;\n}\n\n.titleText {\n  font-size: rem(22px);\n}\n",null],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `PlaylistCard_card__+nYO6`,
	"video": `PlaylistCard_video__Acu3-`,
	"more": `PlaylistCard_more__QO7m8`,
	"title": `PlaylistCard_title__qo4Jp`,
	"titleText": `PlaylistCard_titleText__eQwvb`
};
export default ___CSS_LOADER_EXPORT___;
