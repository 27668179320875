// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Genre_item__3W3Ho {
  width: calc(5.625rem * var(--mantine-scale));
  height: calc(5.625rem * var(--mantine-scale));
  background: var(--mantine-color-gray-0);
}
  [data-mantine-color-scheme='dark'] .Genre_item__3W3Ho {
  background: var(--mantine-color-dark-6);
}
  .Genre_item__3W3Ho {
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: var(--mantine-spacing-xs);
  transition: background-color 0.2s;
}

.Genre_text__zO0nS {
  font-size: var(--mantine-fontSizes-sm);
}
`, "",{"version":3,"sources":["webpack://./src/components/Genre.module.css","<no source>"],"names":[],"mappings":"AAAA;EACE,4CAAgB;EAChB,6CAAiB;EACjB,uCAGC;AAQH;ECdA;EDGE;CCHF;EAAA;EDOE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,kCAAkC;EAClC;CCbF;;ADgBA;EACE,sCAAsC;AACxC","sourcesContent":[".item {\n  width: rem(90px);\n  height: rem(90px);\n  background: light-dark(\n    var(--mantine-color-gray-0),\n    var(--mantine-color-dark-6)\n  );\n  border-radius: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  padding: var(--mantine-spacing-xs);\n  transition: background-color 0.2s;\n}\n\n.text {\n  font-size: var(--mantine-fontSizes-sm);\n}\n",null],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `Genre_item__3W3Ho`,
	"text": `Genre_text__zO0nS`
};
export default ___CSS_LOADER_EXPORT___;
