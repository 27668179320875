// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VideoPlayer_container__MDJbf {
  position: absolute;
  bottom: calc(10rem * var(--mantine-scale));
  right: calc(1.25rem * var(--mantine-scale));
  width: calc(18.75rem * var(--mantine-scale));
  height: calc(9.375rem * var(--mantine-scale));
  z-index: 4;
  pointer-events: all;
  transition: 0.2s;

  /* &[data-visible="true"] {
  } */
}

  .VideoPlayer_container__MDJbf[data-visible="false"] {
    transform: translate3d(320px, 0, 0);
  }

  @media (min-width: 62em) {.VideoPlayer_container__MDJbf {
    bottom: calc(8.125rem * var(--mantine-scale));
    right: calc(2.5rem * var(--mantine-scale));
    width: calc(25rem * var(--mantine-scale));
    height: calc(12.5rem * var(--mantine-scale))
}

    .VideoPlayer_container__MDJbf[data-visible="false"] {
      transform: translate3d(440px, 0, 0);
    }
  }

  @media (min-width: 75em) {.VideoPlayer_container__MDJbf {
    bottom: calc(11.25rem * var(--mantine-scale))
}
  }

  @media (min-width: 133.75em) {.VideoPlayer_container__MDJbf {
    bottom: calc(4.375rem * var(--mantine-scale));
    right: calc(34.375rem * var(--mantine-scale))
}
  }
`, "",{"version":3,"sources":["webpack://./src/components/VideoPlayer.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,0CAAkB;EAClB,2CAAgB;EAChB,4CAAiB;EACjB,6CAAkB;EAClB,UAAU;EACV,mBAAmB;EACnB,gBAAgB;;EA0BhB;KACG;AACL;;EA1BE;IACE,mCAAmC;EACrC;;EAEA,0BAdF;IAeI,6CAAkB;IAClB,0CAAgB;IAChB,yCAAiB;IACjB;AAkBJ;;IAhBI;MACE,mCAAmC;IACrC;EACF;;EAEA,0BAzBF;IA0BI;AAUJ;EATE;;EAEA,8BA7BF;IA8BI,6CAAiB;IACjB;AAKJ;EAJE","sourcesContent":[".container {\n  position: absolute;\n  bottom: rem(160px);\n  right: rem(20px);\n  width: rem(300px);\n  height: rem(150px);\n  z-index: 4;\n  pointer-events: all;\n  transition: 0.2s;\n\n  &[data-visible=\"false\"] {\n    transform: translate3d(320px, 0, 0);\n  }\n\n  @media (min-width: $mantine-breakpoint-md) {\n    bottom: rem(130px);\n    right: rem(40px);\n    width: rem(400px);\n    height: rem(200px);\n\n    &[data-visible=\"false\"] {\n      transform: translate3d(440px, 0, 0);\n    }\n  }\n\n  @media (min-width: $mantine-breakpoint-lg) {\n    bottom: rem(180px);\n  }\n\n  @media (min-width: em(2140)) {\n    bottom: rem(70px);\n    right: rem(550px);\n  }\n\n  /* &[data-visible=\"true\"] {\n  } */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `VideoPlayer_container__MDJbf`
};
export default ___CSS_LOADER_EXPORT___;
