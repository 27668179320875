// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VideoIframe_box__kbSRd:hover .VideoIframe_button__8frDF {
      opacity: 1;
      top: calc(0.5rem * var(--mantine-scale));
    }

.VideoIframe_button__8frDF {
  position: absolute;
  top: calc(0.5rem * var(--mantine-scale));
  color: black;
  background: white;
  z-index: 5;
}

.VideoIframe_button__8frDF:hover {
    color: black;
    background: white;
  }

@media (min-width: 75em) {

.VideoIframe_button__8frDF {
    opacity: 0;
    transition: "0.2s";
    top: calc(-1.375rem * var(--mantine-scale))
}
  }

.VideoIframe_buttonClose__POazA {
  left: calc(0.5rem * var(--mantine-scale));
}

.VideoIframe_buttonHide__6H8Gz {
  left: calc(2.75rem * var(--mantine-scale));
}

.VideoIframe_buttonInfo__iEbN5 {
  right: calc(0.5rem * var(--mantine-scale));
}
`, "",{"version":3,"sources":["webpack://./src/components/VideoIframe.module.css"],"names":[],"mappings":"AAEI;MACE,UAAU;MACV,wCAAa;IACf;;AAIJ;EACE,kBAAkB;EAClB,wCAAa;EACb,YAAY;EACZ,iBAAiB;EACjB,UAAU;AAYZ;;AAVE;IACE,YAAY;IACZ,iBAAiB;EACnB;;AAEA;;AAZF;IAaI,UAAU;IACV,kBAAkB;IAClB;AAEJ;EADE;;AAGF;EACE,yCAAc;AAChB;;AAEA;EACE,0CAAe;AACjB;;AAEA;EACE,0CAAe;AACjB","sourcesContent":[".box {\n  &:hover {\n    .button {\n      opacity: 1;\n      top: rem(8px);\n    }\n  }\n}\n\n.button {\n  position: absolute;\n  top: rem(8px);\n  color: black;\n  background: white;\n  z-index: 5;\n\n  &:hover {\n    color: black;\n    background: white;\n  }\n\n  @media (min-width: $mantine-breakpoint-lg) {\n    opacity: 0;\n    transition: \"0.2s\";\n    top: rem(-22px);\n  }\n}\n\n.buttonClose {\n  left: rem(8px);\n}\n\n.buttonHide {\n  left: rem(44px);\n}\n\n.buttonInfo {\n  right: rem(8px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `VideoIframe_box__kbSRd`,
	"button": `VideoIframe_button__8frDF`,
	"buttonClose": `VideoIframe_buttonClose__POazA`,
	"buttonHide": `VideoIframe_buttonHide__6H8Gz`,
	"buttonInfo": `VideoIframe_buttonInfo__iEbN5`
};
export default ___CSS_LOADER_EXPORT___;
