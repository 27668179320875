// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Video_container__lccve {
  position: relative;
  border-radius: var(--mantine-radius-md);
  transition: 0.2s;
}

  [data-mantine-color-scheme="light"] .Video_container__lccve:hover {
      background-color: var(--mantine-color-gray-0);
    }

  [data-mantine-color-scheme="dark"] .Video_container__lccve:hover {
      background-color: var(--mantine-color-dark-5);
    }

.Video_image__m-O4M {
  width: 100%;
  height: calc(4.6875rem * var(--mantine-scale));
  max-width: calc(6.25rem * var(--mantine-scale));
  border-radius: var(--mantine-radius-md);
}

@media (max-width: 50em) {

.Video_image__m-O4M {
    display: none
}
  }
`, "",{"version":3,"sources":["webpack://./src/components/Video.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,uCAAuC;EACvC,gBAAgB;AAYlB;;EATI;MACE,6CAA6C;IAC/C;;EAGA;MACE,6CAA6C;IAC/C;;AAIJ;EACE,WAAW;EACX,8CAAiB;EACjB,+CAAqB;EACrB,uCAAuC;AAKzC;;AAHE;;AANF;IAOI;AAEJ;EADE","sourcesContent":[".container {\n  position: relative;\n  border-radius: var(--mantine-radius-md);\n  transition: 0.2s;\n\n  [data-mantine-color-scheme=\"light\"] & {\n    &:hover {\n      background-color: var(--mantine-color-gray-0);\n    }\n  }\n  [data-mantine-color-scheme=\"dark\"] & {\n    &:hover {\n      background-color: var(--mantine-color-dark-5);\n    }\n  }\n}\n\n.image {\n  width: 100%;\n  height: rem(75px);\n  max-width: rem(100px);\n  border-radius: var(--mantine-radius-md);\n\n  @media (max-width: $mantine-breakpoint-sm) {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Video_container__lccve`,
	"image": `Video_image__m-O4M`
};
export default ___CSS_LOADER_EXPORT___;
