// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-mantine-color-scheme="light"] .MobileNavigation_container__kHcxu {
    background: var(--mantine-color-gray-0);
  }
  [data-mantine-color-scheme="dark"] .MobileNavigation_container__kHcxu {
    background: var(--mantine-color-dark-6);
  }
`, "",{"version":3,"sources":["webpack://./src/components/MobileNavigation.module.css"],"names":[],"mappings":"AACE;IACE,uCAAuC;EACzC;EAEA;IACE,uCAAuC;EACzC","sourcesContent":[".container {\n  [data-mantine-color-scheme=\"light\"] & {\n    background: var(--mantine-color-gray-0);\n  }\n\n  [data-mantine-color-scheme=\"dark\"] & {\n    background: var(--mantine-color-dark-6);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MobileNavigation_container__kHcxu`
};
export default ___CSS_LOADER_EXPORT___;
