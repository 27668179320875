// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LinkSeeAll_link__uzT76 {
  color: var(--mantine-color-blue-6);
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/LinkSeeAll.module.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,qBAAqB;AACvB","sourcesContent":[".link {\n  color: var(--mantine-color-blue-6);\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `LinkSeeAll_link__uzT76`
};
export default ___CSS_LOADER_EXPORT___;
